import React from 'react';
import { Link } from "react-router-dom";
import Tooltip from '../components/Tooltip';
import Tags from '../components/Tags';
import { MagnifyingGlassIcon, ShieldCheckIcon, ShieldExclamationIcon, XCircleIcon, ArrowPathRoundedSquareIcon, AtSymbolIcon } from '@heroicons/react/20/solid';
import WoocommerceIcon from './Icons/woocommerce';
import WprocketIcon from './Icons/wprocket';
import OneClickLogin from './OneClickLogin';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

function bytesToHuman(bytes) {
  let prefixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let base = 1024;
  let cl = Math.min(parseInt(Math.log(bytes) / Math.log(base), 10), prefixes.length - 1);    
  return (bytes / Math.pow(base, cl)).toFixed(2) + ' ' + prefixes[cl];
}

class Project extends React.Component { 
  componentDidMount() {
    // Update the document title using the browser API
    document.title = `Monitoring`;
  }

  render() {
    const props = this.props;
    var url = this.props.url.replace(/\/$/, '');
    var has_vulns = (this.props.summary?.vulnerabilities?.length) || false;

    const reloadTags = () => {
      fetch(`/api/tags/${this.props.id}/`, {
        headers: {
          'Authorization': 'Bearer ' + this.props.token
        }
      }).then((results) => {
        results.json().then(data => {
          if (data && data.success) {
            this.props.updateTags(this.props.id, data.tags);
          } else if (data && !data.success) {
            this.props.addNotification(data?.message || 'Unexpected error while reloading the tags list', 'error');
          } else if (!data) {
            this.props.unsetToken();
          }
        });
      })
    }
    
    const tags = this.props.tags || [];
    const platform = (this.props.platform === 'wordpress') ? 'WP' : this.props.platform;
    const php_version = this.props.summary?.php_version;
    const php_is_outdated = (php_version && php_version < '8');
    const disk_occupied_space = parseFloat(this.props.summary?.disk_occupied_space || 0);
    const disk_total_space = 10 * 1024 * 1024 * 1024; //this.props.summary?.disk_total_space;
    // const disk_free_space = disk_total_space - disk_occupied_space;
    // let disk_free_space_human = bytesToHuman(disk_free_space);
    let disk_occupied_space_human = '';
    let disk_total_space_human = '';
    if (disk_total_space) {
      disk_occupied_space_human = bytesToHuman(disk_occupied_space);
      disk_total_space_human = bytesToHuman(disk_total_space);
    }
    let disk_space_percentage = 0;
    let disk_space_color = 'bg-green-400';
    let disk_space_border = 'border-green-400';
    if (disk_total_space) {
      disk_space_percentage = Math.min(disk_occupied_space / disk_total_space * 100, 100);
      if (disk_space_percentage >= 55) {
        disk_space_color = 'bg-yellow-500';
        disk_space_border = 'border-yellow-500';
        if (disk_space_percentage >= 75) {
          disk_space_color = 'bg-orange-500';
          disk_space_border = 'border-orange-500';
          if (disk_space_percentage >= 90) {
            disk_space_color = 'bg-red-500';
            disk_space_border = 'border-red-500';  
          }
        }
      }
    }
    const plugins = (this.props.plugins || []);
    const ithemes_security = plugins.find(i => i.path === 'ithemes-security-pro/ithemes-security-pro.php');
    const wp_mail_smtp = plugins.find(i => i.path === 'wp-mail-smtp/wp_mail_smtp.php');
    const woocommerce = plugins.find(i => i.path === 'woocommerce/woocommerce.php');
    const wp_rocket = plugins.find(i => i.path === 'wp-rocket/wp-rocket.php');

    return (
      <div className="col-span-1 md:col-span-1 lg:col-span-1 bg-white rounded-lg shadow p-4 relative transition hover:shadow-lg hover:z-50">

        <div className="flex justify-between mb-1">
          <Link to={"/website/" + this.props.id}><h3 className="text-18-24 font-normal inline-flex items-center space-x-1">
            
            {has_vulns 
              ? <Tooltip width="w-36" title="Vulnerable Release!">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
              </Tooltip>
              : null}

            {props?.ithemes_last_scan?.value?.code === 'vulnerable-software' ?
              <Tooltip className="inline-block" width="w-52" title="Last IThemes Security scan reported a vulnerability!">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
              </Tooltip>
            : null}
            <span className="text-18-24">{this.props.title}</span>
          </h3></Link>
          <OneClickLogin website_id={this.props.id} url={url} token={this.props.token} addNotification={this.props.addNotification} />
        </div>

        <div className="flex justify-between">
          <div className="flex items-center flex-wrap">
            
            <div className="mr-1 mb-1 text-12-16">
              {this.props.summary?.version_update_available 
              ? <Tooltip className={(has_vulns ? 'bg-red-500' : 'bg-yellow-500') + ' inline-block px-0.5 rounded-sm text-white'} title={`Update to ${this.props.summary.version_update_available} is available`}>
                {platform} {this.props.summary?.version}
              </Tooltip> 
              : <span className='inline-block bg-green-400 px-0.5 rounded-sm text-white'>{platform} {this.props.summary?.version}</span>
              }
            </div>

            <div className="mr-1 mb-1 text-12-16">
              {this.props.summary?.php_version 
              ? (php_is_outdated
                ? <Tooltip width="w-48" title="PHP version is deprecated"><span className="bg-yellow-500 inline-block px-0.5 rounded-sm text-white">PHP {this.props.summary?.php_version}</span></Tooltip>
                : <span className="bg-green-400 inline-block px-0.5 rounded-sm text-white">PHP {this.props.summary?.php_version}</span> 
              ) : <span className="bg-slate-400 inline-block px-0.5 rounded-sm text-white">PHP ?</span>
              }
            </div>
            {disk_occupied_space ?
              <div className="mr-1 mb-1 text-12-16">
                <Tooltip width="w-54" title={"Disk space occupied " + disk_space_percentage.toFixed(2) + "%"}>
                  <span className="relative inline-block px-1">{disk_occupied_space_human} / {disk_total_space_human}</span>
                  <span className={"absolute left-0 bg-white rounded-sm border-1p h-full w-full " + disk_space_border}>
                    <span className="absolute z-10 left-0 -top-0.25 px-0.5 text-slate-700 whitespace-nowrap">{disk_occupied_space_human} / {disk_total_space_human}</span>
                    <span className={"absolute z-20 left-0 top-0 h-full overflow-hidden " + disk_space_color} style={{ width: disk_space_percentage + '%' }}>
                      <span className="absolute left-0 -top-0.25 px-0.5 text-white whitespace-nowrap">{disk_occupied_space_human} / {disk_total_space_human}</span>
                    </span>
                  </span>
                </Tooltip>
              </div>
            : null}
          </div>

        </div>

        <div className="flex items-center space-x-0.5 mb-1">
          <div>{
            (
              this.props.connection_status === 'good' ? null :
              this.props.connection_status === 'bad' ? <Tooltip title="Bad connection" className="hover:z-50"><XCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" /></Tooltip> :
              <Tooltip title="Pending connection"><span className='rounded-full bg-gray-500 block h-5 w-5'></span></Tooltip>
            )
          }</div>
          <div>{
            (
              this.props.summary?.blog_public === '1' ? <Tooltip title="Visible to search engines" className="hover:z-50"><MagnifyingGlassIcon className="h-6 w-6 text-green-500" aria-hidden="true" /></Tooltip> : 
              this.props.summary?.blog_public === '0' ? <Tooltip title="Not visible to search engines" className="hover:z-50"><MagnifyingGlassIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" /></Tooltip> : 
              <Tooltip title="Visibility to search engines is unknown"><MagnifyingGlassIcon className="h-6 w-6 text-gray-500" aria-hidden="true" /></Tooltip>
            )
          }</div>
          {
            this.props.summary?.autoupdate_core_enabled === '1' ? <Tooltip title="Auto-updates are enabled" className="hover:z-50"><ArrowPathRoundedSquareIcon className="h-6 w-6 text-green-500" aria-hidden="true" /></Tooltip> : 
            this.props.summary?.autoupdate_core_enabled === '0' ? <Tooltip title="Auto-updates are disabled" className="hover:z-50"><ArrowPathRoundedSquareIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" /></Tooltip> : 
            null
          }
          {
            wp_mail_smtp
              ? wp_mail_smtp.is_active ?
                <Tooltip width="w-64" title="WP Mail SMTP is installed and active" className="hover:z-50"><AtSymbolIcon className="h-6 w-6 text-green-500" aria-hidden="true" /></Tooltip>
                : <Tooltip width="w-48" title="WP Mail SMTP is installed, but inactive" className="hover:z-50"><AtSymbolIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" /></Tooltip>
              : null
          }
          {
            woocommerce
              ? woocommerce.is_active ?
                <Tooltip width="w-64" title="WooCommerce is installed and active" className="hover:z-50"><WoocommerceIcon color="fill-green-500" className="h-6 w-6 text-green-500" aria-hidden="true" /></Tooltip>
                : <Tooltip width="w-48" title="WooCommerce is installed, but inactive" className="hover:z-50"><WoocommerceIcon color="fill-yellow-500" className="h-6 w-6 text-yellow-500" aria-hidden="true" /></Tooltip>
              : null
          }
          {
            wp_rocket
              ? wp_rocket.is_active ?
                <Tooltip width="w-64" title="WP Rocket is installed and active" className="hover:z-50"><WprocketIcon color="fill-green-500" className="h-6 w-6 text-green-500" aria-hidden="true" /></Tooltip>
                : <Tooltip width="w-48" title="WP Rocket is installed, but inactive" className="hover:z-50"><WprocketIcon color="fill-yellow-500" className="h-6 w-6 text-yellow-500" aria-hidden="true" /></Tooltip>
              : null
          }
          {
            ithemes_security
              ? ithemes_security.is_active ?
                <Tooltip width="w-64" title="IThemes Security is installed and active" className="hover:z-50"><ShieldCheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" /></Tooltip>
                : <Tooltip width="w-48" title="IThemes Security is installed, but inactive" className="hover:z-50"><ShieldExclamationIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" /></Tooltip>
              : null
          }
        </div>
                
        <div className="mb-2"><a href={url + '/'} className="text-green-600 hover:text-green-800 max-w-full inline-block overflow-ellipsis overflow-hidden font-light" rel="noreferrer" target="_blank">{url}</a></div>
        
        <Tags website_id={this.props.id} setToken={this.props.setToken} tags={tags} token={this.props.token} addNotification={this.props.addNotification} reloadTags={reloadTags} />
      </div>
    )
  }
}

export default Project;