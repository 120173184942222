import { ArrowUturnLeftIcon } from '@heroicons/react/20/solid';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const navigate = useNavigate();
  const loc = useLocation();
  const params = new URLSearchParams(loc.search);
  const key = params.get('key');
  let initial_step = 1;
  if (key && typeof key === 'string' && key.length === 40) {
    initial_step = 3;
  }
  const [step, setStep] = useState(initial_step);
  const addNotification = props.addNotification;

  async function resetPass (key, password) {
    return fetch('/api/anonymous/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        key: key,
        password: password
      })
    }).then(data => data.json());
  }

  async function forgotPass (email) {
    return fetch('/api/anonymous/forgot-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email
      })
    }).then(data => data.json());
  }

  const submit = async (event) => {
    event.preventDefault();
    if (loading) {
      return false;
    }
    setLoading(true);
    try {
      const response = await forgotPass(username);
      if (response && response.success) {
        setStep(2);
        // props.setToken(response);
      } else {
        console.log(response);
        const message = response?.message || 'Unexpected error occured. Please, try again later.';
        addNotification(message, 'error');
        setLoading(false);
        // display error
      }  
    } catch(error) {
      console.error(error);
      addNotification(error?.message || 'Unexpected error occured. Please, try again later.', 'error');
      setLoading(false);
    }
  }

  const submitPassword = async (event) => {
    event.preventDefault();
    if (loading) {
      return false;
    }
    if (!password) {
      addNotification('Please input your new password.', 'error');
      return false;
    }
    if (password !== password2) {
      addNotification('Please make sure the passwords match.', 'error');
      return false;
    }
    if (password.length <= 5) {
      addNotification('OMG, seriously that password?..', 'error');
      return false;
    }
    setLoading(true);
    try {
      const response = await resetPass(key, password);
      if (response && response.success) {
        // go home
        addNotification('Password has been reset successfully. Use the new credentials to login.', 'success');
        navigate("/", { replace: true });
        // props.setToken(response);
      } else {
        console.log(response);
        const message = response?.message || 'Unexpected error occured. Please, try again later.';
        addNotification(message, 'error');
        setLoading(false);
        // display error
      }  
    } catch(error) {
      console.error(error);
      addNotification(error?.message || 'Unexpected error occured. Please, try again later.', 'error');
      setLoading(false);
    }
  }

  return(
    <div className="m-8 max-w-lg mx-auto">
      {step === 1 ?
      <div>
        <h1 className='text-center text-gray-900 text-3xl font-extrabold tracking-tight mt-6 mb-8'>Input your email</h1>
        <div className='p-6 bg-white rounded shadow transition hover:shadow-lg'>
          <form className='space-y-6' onSubmit={submit}>
            <label className='block'>
              <p className='block text-sm font-medium text-gray-700'>E-mail</p>
              <input onChange={e => setUsername(e.target.value)} required={true} type="email" name="email" className='appearance-none mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md px-3 py-2' />
            </label>
            <div className="flex justify-between items-center">
              <div className="">
                <Link to="/" className="text-sm text-sky-600 hover:text-sky-800 hover:underline inline-flex items-center space-x-2"><ArrowUturnLeftIcon className="w-4 h-4" /><span>I changed my mind, go back</span></Link>
              </div>
              <div className='text-right'>
                <button type="submit" className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'>
                  <svg className={(!loading ? "hidden" : "") + " motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      : 
      (step === 2) ? <div>
        <h1 className='text-center text-gray-900 text-3xl font-extrabold tracking-tight mt-6 mb-8'>Email sent!</h1>
        <div className='p-6 bg-white rounded shadow transition hover:shadow-lg'>
          <h2 className="text-2xl leading-7 text-center">Please, check your email for instructions on how to reset your password.</h2>
        </div>
      </div>
      : 
      <div>
        <h1 className='text-center text-gray-900 text-3xl font-extrabold tracking-tight mt-6 mb-8'>Please pick your new password</h1>
        <div className='p-6 bg-white rounded shadow transition hover:shadow-lg'>
          <form className='space-y-6' onSubmit={submitPassword}>
            <label className='block'>
              <p className='block text-sm font-medium text-gray-700'>Password</p>
              <input onChange={e => setPassword(e.target.value)} required={true} type="password" name="password" className='appearance-none mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md px-3 py-2' />
            </label>
            <label className='block'>
              <p className='block text-sm font-medium text-gray-700'>Confirm Password</p>
              <input onChange={e => setPassword2(e.target.value)} required={true} type="password" name="password2" className='appearance-none mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md px-3 py-2' />
            </label>
            <div className="flex justify-between items-center">
              <div className="">
                <Link to="/" className="text-sm text-sky-600 hover:text-sky-800 hover:underline inline-flex items-center space-x-2"><ArrowUturnLeftIcon className="w-4 h-4" /><span>Go to the home page</span></Link>
              </div>
              <div className='text-right'>
                <button type="submit" className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'>
                  <svg className={(!loading ? "hidden" : "") + " motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      }
    </div>
  )
}