import { useState } from "react";
import { ChevronDoubleUpIcon, ArrowDownTrayIcon, ArrowPathIcon, CheckIcon } from '@heroicons/react/20/solid'
import { apiPostRequest } from "../apiRequest";
import Tooltip from "./Tooltip";

function PluginUpdateButton(props) {
  const [updating, setUpdating] = useState(0);

  const addNotification = props.addNotification;
  const watchUpdateResult = props.watchUpdateResult;
  const website_id = props.website_id;
  const plugin_id = props.plugin_id;
  const token = props.token;

  const update = async (event) => {
    event.preventDefault();
    setUpdating(1);

    try {
      const response = await apiPostRequest(token, '/api/plugin-update', {
        'website_id': website_id,
        'plugin_id': plugin_id
      });
      if (response && response.success) {
        addNotification('Plugin update has been scheduled successfully.', 'success');
        watchUpdateResult(response.track_id, (type) => {
          setUpdating(type === 'success' ? 2 : 0);
        });
      } else {
        // display error
        addNotification(response?.message || 'Something bad happened. Please try again, or contact the administrator', 'error');
        setUpdating(0);
      }  
    } catch(error) {
      // display error
      console.error(error);
      addNotification('Update failed: ' + error.message, 'error');
      setUpdating(0);
    }
  }

  // w-5 h-5 w-6 h-6 w-7 h-7 
  const size = props.size || 7;

  // no package available
  if (!props.update_package) {
    return <Tooltip width="w-52" title="Update package not available" className={"relative inline-flex font-normal align-middle h-" + size + " w-" + size}>
      <span 
        className={"ml-2 -top-1 absolute align-middle inline-flex justify-center items-center px-1 py-1 rounded-full bg-gray-200 text-sm text-gray-500 w-" + size + " h-" + size}
      >
        <span className="sr-only">Update Unavailable</span>
        <ChevronDoubleUpIcon className="h-4 w-4" aria-hidden="true" />
      </span>
    </Tooltip>
  }

  return (
    <span className="inline-flex items-center align-middle">
      {props.update_package 
        ? <Tooltip width="w-40" title="Download plugin" className={"inline-flex ml-2 font-normal align-middle h-" + size + " w-" + size}>
          <a 
            href={props.update_package}
            className={"absolute align-middle inline-flex justify-center items-center px-1 py-1 rounded-full bg-blue-200 text-sm text-gray-700 hover:bg-blue-300 transition group w-" + size + " h-" + size}
          >
            <span className="sr-only">Download plugin</span>
            <ArrowDownTrayIcon className="h-4 w-4 transition group-hover:translate-y-0.5" aria-hidden="true" />
          </a>
        </Tooltip>
        : null}
      <span className={"relative inline-flex ml-2 align-middle h-" + size}>
      {updating === 2
      ? <span
          className={"absolute align-middle inline-flex justify-center items-center px-1 py-1 rounded-full bg-gray-800 text-sm font-medium text-white transition group w-" + size + " h-" + size}
        >
          <span className="sr-only">Updated</span>
          <CheckIcon className="h-4 w-4" aria-hidden="true" />
        </span>
      : (
        updating === 1 ? 
        <span
          className={"absolute align-middle inline-flex justify-center items-center px-1 py-1 rounded-full bg-yellow-200 text-sm font-medium text-gray-700 transition group w-" + size + " h-" + size}
        >
          <span className="sr-only">Updating the plugin..</span>
          <ArrowPathIcon className="h-4 w-4 animate-spin" aria-hidden="true" />
        </span>
        : 
        <Tooltip width="w-40" title="Update plugin" className="inline-flex font-normal align-middle">
          <button 
            type="button"
            onClick={update}
            className={"update-plugin-button absolute align-middle inline-flex justify-center items-center px-1 py-1 rounded-full bg-green-200 text-sm font-medium text-gray-700 hover:bg-green-300 transition group w-" + size + " h-" + size}
          >
            <span className="sr-only">Update Plugin</span>
            <ChevronDoubleUpIcon className="h-4 w-4 transition group-hover:-translate-y-0.5" aria-hidden="true" />
          </button>
        </Tooltip>
      )}
      </span>
    </span>
  )
}

export default PluginUpdateButton;