import React from 'react';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import moment from 'moment';
import { apiGetRequest, apiPostRequest } from '../apiRequest';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import copyToClipboard from '../inc/clipboard';
const ReactSwal = withReactContent(Swal);

class TeamMember extends React.Component {

  editMember = () => {
    ReactSwal.fire({
      html: <div className="text-left text-sm text-gray-900 space-y-3">
        <div className="">
          <label>Name</label>
          <div><input id="name" type="text" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" defaultValue={this.props.name} /></div>
        </div>
        <div className="">
          <label>E-mail</label>
          <div><input id="email" type="email" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" defaultValue={this.props.email} /></div>
        </div>
        <div className="">
          <label>Role</label>
          <div><select id="role" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" defaultValue={this.props.role}>
            <option value="admin">Admin</option>
          </select>
          </div>
        </div>
      </div>,
      showCancelButton: true,
      confirmButtonText: 'Save',
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#name').value
        const email = Swal.getPopup().querySelector('#email').value
        const role = Swal.getPopup().querySelector('#role').value
        if (!name || !email) {
          Swal.showValidationMessage(`Please enter the name and email`);
        }
        return { name, email, role }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const value = result.value;
        apiPostRequest(this.props.token, `/api/team/${this.props.id}/update`, {
          name: value.name,
          email: value.email,
          role: value.role,
        }).then(data => {
          if (data && data.success) {
            this.props.addNotification('The user was updated successfully.', 'success');
            this.props.loadMembers();
          } else {
            this.props.addNotification(data?.message || 'An unexpected error has occured. Please, contact the administrator.', 'error');
          }
        }).catch((error) => {
          this.props.addNotification(error?.message || 'An unexpected error has occured. Please, contact the administrator.', 'error');
        });
      }
    });
  }

  deleteMember = () => {
    Swal.fire({
      title: 'Are you sure you want to delete ' + (this.props.name || this.props.email) + '?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        title: 'text-lg font-light'
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        apiPostRequest(this.props.token, `/api/team/${this.props.id}/delete`).then(data => {
          if (data && data.success) {
            this.props.addNotification('Member removed successfully', 'success');
            this.props.loadMembers();
          } else if (!data && this.props.unsetToken) {
            this.props.unsetToken();
          } else if (data && data.message) {
            this.props.addNotification(data.message, 'error');
          }
        });
      }
    });
  }

  render() {
    const props = this.props;
    return <tr key={props.email}>
      <td className="px-6 py-4 align-top whitespace-nowrap">
        <div className="text-sm text-gray-900 overflow-hidden max-w-xs">{props.name}</div>
      </td>
      <td className="px-6 py-4 align-top whitespace-nowrap">
        <div className="text-sm text-gray-900 overflow-hidden max-w-xs">{props.email}</div>
      </td>
      <td className="px-6 py-4 align-top whitespace-nowrap">
        <div className="text-sm text-gray-900 overflow-hidden max-w-xs">{props.role}</div>
      </td>
      <td className="px-6 py-4 align-top whitespace-nowrap">
        <div className="text-sm text-gray-900 overflow-hidden max-w-xs">{moment(props.created_at).format('MMMM Do, h:mm:ss A')} EST</div>
      </td>
      <td className="px-6 py-4 align-top whitespace-nowrap">
        {(this.props.role === 'admin' || this.props.is_current_user) ?
        <div className="text-sm text-gray-900 overflow-hidden max-w-xs space-x-2">
          {this.props.role === 'admin' || this.props.is_current_user ?
            <span className="transition cursor-pointer inline-flex items-center space-x-1 text-sky-500 hover:text-sky-700" onClick={this.editMember}><PencilSquareIcon className="w-5 h-5" /><span className="hidden">Edit</span></span>
          : null}

          {!this.props.is_current_user 
          ? <span className="transition cursor-pointer inline-flex items-center space-x-1 text-sky-500 hover:text-sky-700" onClick={this.deleteMember}><TrashIcon className="w-5 h-5" /><span className="hidden">Delete</span></span>
          : null}

        </div>
        : null}
      </td>
    </tr>
  }
}

class Team extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'loading': true,
      'team': []
    };
  }

  componentDidMount() {
    if (this.state.loading || this.state.reload) {
      this.loadMembers();
    }
  }

  addMember = () => {
    ReactSwal.fire({
      html: <div className="text-left text-sm text-gray-900 space-y-3">
        <div className="">
          <label>Name</label>
          <div><input id="name" type="text" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" /></div>
        </div>
        <div className="">
          <label>E-mail</label>
          <div><input id="email" type="email" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" /></div>
        </div>
        <div className="">
          <label>Role</label>
          <div><select id="role" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300">
            <option value="admin">Admin</option>
          </select>
          </div>
        </div>
      </div>,
      showCancelButton: true,
      confirmButtonText: 'Save',
      preConfirm: () => {
        const name = Swal.getPopup().querySelector('#name').value
        const email = Swal.getPopup().querySelector('#email').value
        const role = Swal.getPopup().querySelector('#role').value
        if (!name || !email) {
          Swal.showValidationMessage(`Please enter the name and email`);
        }
        return { name, email, role }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const value = result.value;
        apiPostRequest(this.props.token, `/api/team/add`, {
          name: value.name,
          email: value.email,
          role: value.role
        }).then(data => {
          if (data && data.success) {
            this.props.addNotification('The user was added successfully. The temporary password is already in your clipboard :)', 'success');
            this.loadMembers();
            copyToClipboard(data.temp_password);
          } else {
            this.props.addNotification(data?.message || 'An unexpected error has occured. Please, contact the administrator.', 'error');
          }
        }).catch((error) => {
          this.props.addNotification(error?.message || 'An unexpected error has occured. Please, contact the administrator.', 'error');
        });
      }
    });
  }

  loadMembers = () => {
    apiGetRequest(this.props.token, '/api/team').then((data) => {
      if (data && data.team) {
        this.setState({
          team: data.team
        });
      }
    }).catch((error) => {
      console.error(error?.message);
      // this.props.unsetToken();
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-6 md:gap-4">
          <div className="col-span-1 md:col-span-2 xl:col-span-1 mt-2">
            {this.props.menu}
          </div>
          <div className="col-span-4 xl:col-span-5">
            <div className="mt-2 mb-4">
              {this.state.loading 
                ? <div>Loading...</div> 
                : <div>
                    <div className='align-middle inline-block min-w-full'>
                      <h2 className="text-32-40 font-semibold mb-3">Team Members</h2>
                      <div className='shadow overflow-hidden border-b border-gray-200 sm:rounded-lg'>
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Role
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Created At
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {this.state.team.sort((a, b) => {
                              return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
                            }).map((t, i) => {
                              return <TeamMember {...t} token={this.props.token} unsetToken={this.props.unsetToken} loadMembers={this.loadMembers} addNotification={this.props.addNotification} key={t.email} />
                            })}
                          </tbody>
                        </table>
                      </div>

                      <div onClick={this.addMember} className='cursor-pointer inline-flex mt-4 px-6 py-2 bg-blue-400 rounded-xl shadow text-sm font-light text-neutral-100 transition text-center justify-center items-center hover:bg-blue-500 hover:text-white hover:shadow-md'>Add New Member +</div>

                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TeamWithLocation = function(props) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  
  return (
    <Team query={query} {...props} />
  )
}

export default TeamWithLocation;
