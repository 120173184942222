import { FaceFrownIcon } from '@heroicons/react/24/outline';
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className="container">
      <div className="grid grid-cols-1">
        <div className="col-span-1">
          <div className="p-10 bg-white mt-10 text-center">
            <h1 className="text-6xl text-slate-500 inline-flex justify-center justify-items-center items-center"><FaceFrownIcon className="h-16 w-16 text-slate-500 align-middle inline mr-2" aria-hidden="true" /> Not Found!</h1>
            <p className="text-xl">Try going to the <Link to="/" className="text-blue-500">homepage</Link> maybe?</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound;