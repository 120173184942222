import React from 'react';
import { NavLink } from "react-router-dom";
import { apiGetRequest } from '../apiRequest';

class VulnerabilityBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    }
  }

  expand = () => {
    this.setState({
      expanded: true
    });
  }

  render() {
    const props = this.props;
    return <div key={props.id} className="bg-white p-2 text-xs">
      <div className="font-bold">{props.version !== '0' ? (props.version + ' - ' + props.version_end) : ('All versions until ' + props.version_end)}</div>
      <div className="text-neutral-500">{props.id}</div>
      <div className={this.state.expanded ? "" : "max-h-16 overflow-hidden line-clamp-3"}>{props.vulns}</div>
      {!this.state.expanded ?
        <div className='cursor-pointer text-xs text-blue-400' onClick={this.expand}>Read More</div>
      : null}
    </div>
  }
}

let releases = null;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_vulnerabilities: true,
      releases: {}
    }
    if (releases) {
      this.state.releases = releases;
      this.state.loading_vulnerabilities = false;
    }
  }

  componentDidMount() {
    if (!releases) {
      this.loadVulnerabilities().catch(e => {
        console.error(e);
      });
    }
  }

  loadVulnerabilities = async () => {
    try {
      const token = this.props.token;
      const response = await apiGetRequest(token, '/api/releases-and-vulnerabilities');
      if (response && response.success) {
        releases = response.releases;
        this.setState({
          releases: response.releases
        });
      } else {
        // display error
        // addNotification(response?.message || 'Something bad happened. Please try again, or contact the administrator', 'error');
      }  
    } catch(error) {
      // display error
      console.error(error);
    }
    this.setState({
      loading_vulnerabilities: false
    })
  }


  render() {
    const logout = () => {
      this.props.unsetToken();
    }
    return (
      <div className='space-y-3 pt-2'>
        <div><NavLink to="/" className={({ isActive }) => isActive ? 'font-bold' : 'font-regular hover:underline' }>My Websites</NavLink></div>
        <div><NavLink to="/website-add" className={({ isActive }) => isActive ? 'font-bold' : 'font-regular hover:underline' }>Add Website</NavLink></div>
        <div><NavLink to="/plugins" className={({ isActive }) => isActive ? 'font-bold' : 'font-regular hover:underline' }>Plugins</NavLink></div>
        <div><NavLink to="/account" className={({ isActive }) => isActive ? 'font-bold' : 'font-regular hover:underline' }>Profile</NavLink></div>
        <div><NavLink to="/team" className={({ isActive }) => isActive ? 'font-bold' : 'font-regular hover:underline' }>Team</NavLink></div>
        <hr />
        <div><button type='button' className="font-regular hover:underline" onClick={logout}>Logout</button></div>
        <hr />
        <a download href="/download/incore-wordpress-maintenance.zip" className='inline-flex xl:w-full justify-start py-2 px-4 border border-transparent shadow-sm text-md font-normal rounded-md text-white bg-sky-600 transition hover:bg-sky-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800'>
          <img src="/images/wp-white.svg" width="28" height="28" alt="" className='mr-2' /> WordPress Plugin
        </a>
        {!this.state.loading_vulnerabilities &&
          this.state.releases.wordpress ?
          <div>
            <a className="inline-flex xl:w-full justify-start py-2 px-4 border border-transparent shadow-sm text-md font-normal rounded-md text-white bg-slate-600 transition hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800" href={this.state.releases.wordpress.zip}><img src="/images/wp-white.svg" width="28" height="28" alt="" className='mr-2' /> Wordpress {this.state.releases.wordpress.version} (zip)</a>
          </div>
        : null}
        <hr />
        {!this.state.loading_vulnerabilities &&
          this.state.releases?.wordpress?.vulnerabilities && this.state.releases.wordpress.vulnerabilities.length ?
          <div>
            <div className="mt-1">
                Security threats
                <div className="space-y-1">{this.state.releases.wordpress.vulnerabilities.map(v => {
                  return <VulnerabilityBlock key={v.id} id={v.id} version={v.version} version_end={v.version_end} vulns={v.vulns} />
                })}</div>
              </div>
          </div>
        : null}
      </div>
    )
  }
}

export default Menu;