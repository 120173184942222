import React from 'react';

class TagWithSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    }
  }

  selectTag = () => {
    var selected = !this.state.selected;
    this.setState({ selected: selected });
    this.props.onSelect && this.props.onSelect(selected, this.props.title);
  }

  render() {
    const color = this.props.color;
    const title = this.props.title;
    var text_color = 'text-slate-900';
    if (color.match(/400|500|600|700|800|900/)) {
      text_color = 'text-white';
    }
    const selected = this.state.selected ? 'opacity-100' : 'opacity-50';

    return <span className='align-top text-sm select-none' onClick={this.selectTag}>
      <span className={`cursor-pointer mr-1 mb-1 text-sm group h-8 inline-flex items-center px-3 rounded-full relative ${selected} ${text_color} bg-${color}`}>
        {title}
      </span>
    </span>;
  }
}

export default TagWithSelect;