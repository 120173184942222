import React from 'react';
import { Link } from "react-router-dom";
import Tooltip from '../components/Tooltip';
import { ShieldExclamationIcon } from '@heroicons/react/solid';
import PluginUpdateButton from '../components/PluginUpdateButton';

const PluginSummary = function(props) {
  var plugins = {};
  for (var website of props.websites) {
    if (website.plugins && Array.isArray(website.plugins)) {
      for (var plugin of website.plugins) {
        plugins[plugin.plugin_id] = plugins[plugin.plugin_id] || { 
          title: plugin.title,
          path: plugin.path,
          url: plugin.url,
          plugin_id: plugin.plugin_id,
          available_version: plugin.available_version,
          update_package: plugin.update_package,
          websites: [],
          update_websites: []
        };
        if (plugin.available_version) {
          // the latest latest :)
          if (!plugins[plugin.plugin_id].available_version || plugin.available_version > plugins[plugin.plugin_id].available_version) {
            plugins[plugin.plugin_id].available_version = plugin.available_version;
          }
          plugins[plugin.plugin_id].update_websites.push({
            id: website.id,
            title: website.title,
            url: website.url,
            plugin_id: plugin.plugin_id,
            update_package: plugin.update_package,
            version: plugin.version,
            available_version: plugin.available_version,
            has_vulns: plugin.has_vulns
          });
        } else {
          plugins[plugin.plugin_id].websites.push({
            id: website.id,
            title: website.title,
            url: website.url,
            plugin_id: plugin.plugin_id,
            update_package: plugin.update_package,
            version: plugin.version,
            available_version: plugin.available_version,
            has_vulns: plugin.has_vulns
          });  
        }
      }
    }
  }

  var plugin_ids = Object.keys(plugins).sort((a, b) => {
    if (plugins[a].title.toLowerCase() < plugins[b].title.toLowerCase()) {
      return -1;
    } else {
      return 1;
    }
  }).sort((a, b) => {
    if (plugins[a].update_websites.length && !plugins[b].update_websites.length) {
      return -1;
    } else if (!plugins[a].update_websites.length && plugins[b].update_websites.length) {
      return 1;
    } else {
      return 0;
    }
  });
  var table = plugin_ids.map((plugin_id, i) => {
    const has_updates = plugins[plugin_id].update_websites.length;
    return <tr key={plugin_id}>
      <td className={"px-6 py-4 align-top whitespace-nowrap " + (has_updates ? 'bg-yellow-50' : '')}>
        <div className="text-md font-medium text-gray-900 max-w-sm whitespace-normal">{plugins[plugin_id].title}</div>
        <div>
          {plugins[plugin_id].websites.map(w => {
            return <div key={w.id}>
              <Link className='text-blue-500 transition hover:text-blue-600 hover:underline' to={"/website/" + w.id}>{w.title}</Link> - {w.version} {w.has_vulns 
              ? <div className='mr-1 inline-block text-14-16'><Tooltip width="w-36" title="Vulnerable Release">
                <ShieldExclamationIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </Tooltip></div>
              : null}
            </div>
          })}
        </div>
      </td>
      <td className={"px-6 py-4 align-top whitespace-nowrap " + (has_updates ? 'bg-yellow-50' : '')}>
        <div className="text-sm text-gray-900 overflow-hidden max-w-xs">{plugins[plugin_id].url}</div>
      </td>
      <td className={"px-6 py-4 align-top whitespace-nowrap " + (has_updates ? 'bg-yellow-50' : '')}>
        {/*<div className="text-sm text-gray-900">{plugins[plugin_id].available_version}</div>*/}
        <div>
          {plugins[plugin_id].update_websites.map(w => {
            return <div key={w.id}>
              <Link className='text-blue-500 transition hover:text-blue-600 hover:underline' to={"/website/" + w.id}>{w.title}</Link> - {w.version} {w.has_vulns 
              ? <div className='mr-1 inline-block text-14-16'><Tooltip width="w-36" title="Vulnerable Release">
                <ShieldExclamationIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </Tooltip></div>
              : null} -&gt; {w.available_version}
              {w.update_package
                ? <PluginUpdateButton size="5" update_package={w.update_package} website_id={w.id} plugin_id={w.plugin_id} addNotification={props.addNotification} token={props.token} watchUpdateResult={props.watchUpdateResult} />
                : null}
            </div>
          })}
        </div>
      </td>
    </tr>
  });
  
  return (
    <div>
      <h2 className="text-32-40 font-semibold mb-3">Plugins Overview</h2>
      <div className='mb-3'><strong>{props.websites.length }</strong> websites, <strong>{plugin_ids.length}</strong> unique plugins.</div>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Plugin
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      URL
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Available Update
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {table}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

class Plugins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'loading': true,
      'reload': false
    };
    // if (props.websites.length) {
    //   if (!props.websites[0].plugins) {
    //     this.state.reload = true;
    //   }
    //   this.state.loading = false;
    // }
    if (props.query && props.query.reload) {
      this.state.reload = true;
    }
  }

  componentDidMount() {
    if (this.state.loading || this.state.reload) {
      fetch('/api/websites/list?plugins=1', {
        headers: {
          'Authorization': 'Bearer ' + this.props.token
        }
      }).then((results) => {
        results.json().then(data => {
          if (data && data.websites) {
            this.props.setWebsites(data.websites);
          } else if (!data || !data.success) {
            this.props.setToken(null);
          }
          this.setState({ loading: false });
        });
      })
    }
  }

  render() {
    const props = this.props;
    var websites = props.websites;
    return (
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-6 md:gap-4">
          <div className="col-span-1 md:col-span-2 xl:col-span-1 mt-2">
            {props.menu}
          </div>
          <div className="col-span-4 xl:col-span-5">
            <div className="mt-2 mb-4">
              {this.state.loading 
                ? <div>Loading...</div> 
                : <div>
                  <PluginSummary websites={websites} addNotification={props.addNotification} token={props.token} watchUpdateResult={props.watchUpdateResult} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Plugins;
