import React from "react";
import { useParams } from "react-router-dom";
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid';
import { useNavigate } from "react-router-dom";
import OneClickLogin from "../components/OneClickLogin";
import PluginUpdateButton from "../components/PluginUpdateButton";
import { Link } from "react-router-dom";
import Tooltip from "../components/Tooltip";

class Plugin extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      plugins: []
    };
    this.pluginList = [];
  }

  componentDidMount() {
    // additional details
    fetch(`/api/plugin/${this.props.params.id}`, {
      headers: {
        'Authorization': 'Bearer ' + this.props.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data && data.plugins) {
        this.setState({ 
          plugins: data.plugins, 
          loading: false 
        });
      } else if (!data || !data.success) {
        this.props.unsetToken();
      } else {
        this.setState({ loading: false });  
      }
    });
  }

  update = () => {
    let elements = document.querySelectorAll('.update-all-plugins .update-plugin-button');
    for (let elem of elements) {
      elem.click();
    }
  }
  
  render() {
    const props = this.props;
    let has_updates = this.state.plugins?.map((p, index) => { return p.update_package }).filter(f => f);
    let size = 7;
    
    return (
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-6 md:gap-4">
          <div className="col-span-1 md:col-span-2 xl:col-span-1 mt-2">
            {this.props.menu}
          </div>
          <div className="col-span-4 xl:col-span-5">
            <div className="mt-2 mb-4">
              {this.state.loading && !this.state.plugins?.length
              ? <div>
                  <h2 className="text-40-56 font-bold">Loading...</h2>
                </div>
              : <div>
                  <h2 className="text-40-56 font-bold">{this.state.plugins[0]?.plugin_title}</h2>
                  <div className="bg-white rounded-lg shadow">
                    <div className="md:grid md:grid-cols-3">
                      <div className="col-span-2 pt-4">
                        <div className="px-6 mb-3">
                          <dl>
                            <div className="grid py-5 grid-cols-2 px-6 gap-4 border-b-1p border-gray-100">
                              <dt className="leading-6 text-lg font-medium text-gray-700 col-span-1">
                                Website
                              </dt>
                              <dd className="leading-6 text-lg font-medium text-gray-700 col-span-1 inline-flex items-center gap-2">
                                <span>Version</span> {has_updates.length ? <Tooltip width="w-64" title="Update all plugins listed below" className="h-7 inline-flex font-normal">
                                  <button 
                                    type="button"
                                    onClick={this.update}
                                    className={"absolute align-middle inline-flex justify-center items-center px-1 py-1 rounded-full bg-green-200 text-sm font-medium text-gray-700 hover:bg-green-300 transition group w-" + size + " h-" + size}
                                  >
                                    <span className="sr-only">Update all plugins listed below</span>
                                    <ChevronDoubleUpIcon className="h-4 w-4 transition group-hover:-translate-y-0.5" aria-hidden="true" />
                                  </button>
                                </Tooltip> : null}
                              </dd>
                            </div>
                            <div className="update-all-plugins">
                              {this.state.plugins?.map((p, index) => {
                                return <div key={p.id} className={"hidden md:grid py-5 grid-cols-2 px-6 gap-4 " + (index % 2 ? 'bg-gray-50' :  'bg-white')}>
                                  <dt className="text-sm font-medium text-gray-900 col-span-1 inline-flex flex-wrap items-center gap-2">
                                    <OneClickLogin website_id={p.id} url={p.url + '/'} token={props.token} addNotification={props.addNotification} /><Link to={"/website/" + p.id}>{p.title}</Link>
                                    <div className="w-full font-light text-gray-600"><a href={p.url} rel="noreferrer" target="_blank" className="hover:text-blue-500">{p.url}</a></div>
                                  </dt>
                                  <dd className="text-sm font-normal text-gray-900 col-span-1 inline-flex flex-wrap items-center gap-2">
                                    {p.version}
                                    {p.available_version
                                    ? <span className="inline-flex items-center gap-2">
                                        <ArrowLongRightIcon className="w-5 h-5 text-gray-900 inline-block" /><span className="font-medium">{p.available_version}</span>
                                        <PluginUpdateButton available_version={p.available_version} update_package={p.update_package} website_id={p.id} plugin_id={p.plugin_id} addNotification={props.addNotification} token={props.token} watchUpdateResult={props.watchUpdateResult} />
                                      </span> 
                                    : null}
                                  </dd>
                                </div>
                              })}
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PluginWithRouter = (props) => {
  const navigate = useNavigate();

  return <Plugin 
  
    {...props}
    navigate={navigate}
    params={useParams()}
  />
};

export default PluginWithRouter;