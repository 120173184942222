import { XMarkIcon, CheckIcon } from '@heroicons/react/20/solid';
import React, { createRef } from 'react';
import TagWithDelete from './TagWithDelete';

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adding: 0,
      title: '',
      color: ''
    }
    this.inputRef = createRef();
  }

  startAdding = () => {
    this.setState({ adding: 1 }, () => {
      if (this.inputRef && this.inputRef.current) {
        this.inputRef.current.focus();
      }
    });
  }

  addTag = () => {
    if (this.state.adding === 2) {
      return;
    }
    this.setState({
      adding: 2
    });
    fetch('/api/tag-add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token
      },
      body: JSON.stringify({
        website_id: this.props.website_id,
        title: this.state.title,
        color: this.state.color
      })
    })
    .then(results => results.json())
    .then(data => {
      this.setState({
        adding: 1
      });
      if (data && data.success) {
        this.setState({
          adding: 0
        });
        this.props.addNotification(data?.message || 'Tag added successfully.', 'success');
        this.props.reloadTags();
      } else if (data && !data.success) {
        this.props.addNotification(data?.message || 'Unexpected error. Please, try again later.', 'error');
      } else if (!data) {
        this.props.addNotification(data?.message || 'Unexpected error. Please, try again later.', 'error');
      }
    })
    .catch(err => {
      this.props.addNotification(err?.message || 'Unexpected error. Please, try again later.', 'error')
    });
  }

  hitKey = (e) => {
    if (e.code === 'Enter') {
      this.addTag();
    }
  }

  render() {
    // don't remove this list
    // const colors = ['bg-blue-400']

    return <div>
      {this.state.adding 
      ? <div className='flex mb-2'>
        <button onClick={() => { this.setState({ adding: 0 })}} type='button' className='group inline-flex items-center h-10 rounded-l-sm text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-pink-50 text-pink-600 hover:bg-pink-100 hover:text-pink-700 focus:ring-pink-600 dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 dark:hover:text-white dark:focus:ring-slate-500'><XMarkIcon className="h-5 w-5 text-slate-700" /></button>
        <input ref={this.inputRef} data-input={true} value={this.state.title} onKeyDown={this.hitKey} onChange={(event) => { this.setState({ title: event.target.value }) }} type='text' className='' placeholder='New tag title' />
        <button onClick={this.addTag} type='button' className='group inline-flex items-center h-10 pb-0.5 rounded-r-sm text-sm font-semibold whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-sky-50 text-sky-600 hover:bg-sky-100 hover:text-sky-700 focus:ring-sky-600 dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 dark:hover:text-white dark:focus:ring-slate-500'><CheckIcon className="h-5 w-5 text-slate-700" /></button>
      </div>
      : null}
      <div className="flex flex-wrap">
        {this.props.tags.map(i => {
          return <TagWithDelete key={i.id} token={this.props.token} website_id={this.props.website_id} id={i.id} reloadTags={this.props.reloadTags} color={i.color} title={i.title} addNotification={this.props.addNotification} />
        })}
        <button onClick={this.startAdding} type='button' className='group inline-flex items-center h-6 pb-0.5 rounded-full text-xs font-normal whitespace-nowrap px-3 focus:outline-none focus:ring-2 bg-sky-50 text-sky-600 hover:bg-sky-100 hover:text-sky-700 focus:ring-sky-600 dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600 dark:hover:text-white dark:focus:ring-slate-500'>Add Tag +</button>
      </div>
    </div>
  }
}

export default Tags;