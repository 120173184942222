import { XMarkIcon } from '@heroicons/react/20/solid';
import React from 'react';
import Tooltip from '../components/Tooltip';

class TagWithDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden_x: true
    }
  }

  removeTag = () => {
    const id = this.props.id;
    fetch(`/api/tags/${id}/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token
      }
    })
    .then(results => results.json())
    .then(data => {
      if (data && data.success) {
        this.props.addNotification(data?.message || 'Tag removed successfully.', 'success');
        this.props.reloadTags();
      } else if (!data || !data?.success) {
        this.props.addNotification(data?.message || 'Unexpected error. Please, try again later.', 'error');
      }
    })
    .catch(err => {
      this.props.addNotification(err?.message || 'Unexpected error. Please, try again later.', 'error')
    });
  }

  cycleColor = () => {
    // bg-slate-500 bg-yellow-500 bg-blue-400 bg-green-400 bg-orange-400 bg-red-400
    const colors = [
      'slate-500',
      'yellow-500',
      'orange-400',
      'red-400',
      'blue-400',
      'green-500'
    ];
    const id = this.props.id;
    let color;
    for (let a = 0; a < colors.length; a++) {
      if (colors[a] === this.props.color) {
        if (a < colors.length-1) {
          color = colors[a+1];
        } else {
          color = colors[0];
        }
        break;
      }
    }
    if (!color) {
      color = colors[0];
    }
    fetch(`/api/tag/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token
      },
      body: JSON.stringify({
        color: color
      })
    })
    .then(results => results.json())
    .then(data => {
      if (data && data.success) {
        // this.props.addNotification(data?.message || 'Tag updated successfully.', 'success');
        this.props.reloadTags();
      } else if (!data || !data?.success) {
        this.props.addNotification(data?.message || 'Unexpected error. Please, try again later.', 'error');
      }
    })
    .catch(err => {
      this.props.addNotification(err?.message || 'Unexpected error. Please, try again later.', 'error')
    });
  }

  render() {
    const color = this.props.color;
    const title = this.props.title;
    var text_color = 'text-slate-900';
    if (color.match(/400|500|600|700|800|900/)) {
      text_color = 'text-white';
    }
    const hidden_x = this.state.hidden_x;

    return <span className='align-top inline-flex text-xs' onMouseEnter={() => { this.setState({ hidden_x: false })}} onMouseLeave={() => { this.setState({ hidden_x: true })}} >
      <span className={`mr-1 text-xs group h-6 inline-flex items-center px-3 rounded-full relative ${text_color} bg-${color}`}>
        {title}
        <span onClick={this.cycleColor} className={`absolute -top-1 -left-1 rounded-sm overflow-hidden inline-flex flex-wrap items-center justify-end w-4 h-4 cursor-pointer text-center shadow-sm transition ${hidden_x ? 'opacity-0' : 'opacity-100'}`}>
          <span className={'inline-flex w-2 h-2 bg-slate-500'}></span>
          <span className={'inline-flex w-2 h-2 bg-yellow-500'}></span>
          <span className={'inline-flex w-2 h-2 bg-blue-400'}></span>
          <span className={'inline-flex w-2 h-2 bg-green-500'}></span>
        </span>
        <span className={`absolute -top-1 -right-1 rounded-full inline-flex items-center justify-center w-4 h-4 bg-red-500 text-center transition ${hidden_x ? 'opacity-0' : 'opacity-100'}`}>
          <Tooltip title="Remove tag" width="w-auto whitespace-nowrap" className='text-16-14'><button onClick={this.removeTag} type='button' className='cursor-pointer w-4 h-4 inline-flex items-center justify-center'><XMarkIcon className='w-3 h-3' /></button></Tooltip>
        </span>
      </span>
    </span>;
  }
}

export default TagWithDelete;