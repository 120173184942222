import { useState } from "react";

const Tooltip = function(props) {
  const title = props.title;

  const [show, setShow] = useState(false);

  return (
    <div className={"relative " + (props.className || '')} onMouseOver={() => setShow(true)} onMouseOut={() => setShow(false)}>
      {show ? <div className={(props.width || 'w-32') + ' absolute text-black bg-white bottom-full text-14-18 p-2 transform -translate-y-3 shadow rounded-sm'}>{title}</div> : null}
      {props.children}
    </div>
  )
}

export default Tooltip;