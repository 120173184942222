import React from 'react';
import { Link, useLocation } from "react-router-dom";
import queryString from 'query-string';
import Project from '../components/Project';
import { apiGetRequest } from '../apiRequest';
import TagWithSelect from '../components/TagWithSelect';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import Tooltip from '../components/Tooltip';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'loading': true,
      'loadingReload': false,
      'reload': false,
      'tags': [],
      'selected_tags': []
    };
    if (props.websites.length) {
      this.state.loading = false;
      this.state.tags = this.collectTags(props.websites);
    }
    if (props.query.reload) {
      this.state.reload = true;
    }
  }

  componentDidMount() {
    if (this.state.loading || this.state.reload) {
      this.loadWebsiteList();
    }
  }

  loadWebsiteList = () => {
    return apiGetRequest(this.props.token, '/api/websites/list').then((data) => {
      if (data && data.websites) {
        this.props.setWebsites(data.websites);
        var tags = this.collectTags(data.websites);
        this.setState({ tags: tags });
      } else if (!data || !data.success) {
        this.props.unsetToken();
      }
      this.setState({ loading: false });
    })
  }

  reloadList = () => {
    if (this.state.loadingReload) {
      return false;
    }
    this.setState({
      loadingReload: true
    });
    this.loadWebsiteList().finally(() => {
      this.setState({
        loadingReload: false
      });
    });
  }

  collectTags(websites) {
    // collect tags
    let tags = [];
    websites.forEach(website => {
      if (website.tags && website.tags.length) {
        website.tags.forEach(tag => {
          if (!tags.find(t => t.title === tag.title)) {
            tags.push({
              color: tag.color,
              title: tag.title
            });
          }    
        })
      }
    });
    return tags;
  }

  selectTag = (selected, title) => {
    var selected_tags = this.state.selected_tags.slice(0);
    if (!selected) {
      var index = selected_tags.indexOf(title);
      if (index !== -1) {
        selected_tags.splice(index, 1);
      }
    } else {
      selected_tags.push(title);
    }
    this.setState({
      selected_tags: selected_tags
    });
  }

  updateTags = (id, tags) => {
    this.props.updateTags(id, tags);
    var new_tags = this.collectTags(this.props.websites);
    this.setState({ tags: new_tags });
  }

  render() {
    let filter = (a) => a; // return all
    if (this.state.selected_tags && this.state.selected_tags.length) {
      filter = (website) => {
        var has_all = true;
        for (let t of this.state.selected_tags) {
          if (!website.tags.find(tag => tag.title === t)) {
            has_all = false;
            break;
          }
        } 
        return has_all;
      }
    }
    
    return (
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-6 md:gap-4">
          <div className="col-span-1 md:col-span-2 xl:col-span-1 mt-2">
            {this.props.menu}
          </div>
          <div className="col-span-4 xl:col-span-5">
            <div className="mt-2 mb-4">
              {this.state.loading 
                ? <div>Loading...</div> 
                : <div>
                  <h2 className="text-32-40 font-semibold mb-3">Your Websites</h2>
                  <div className='mb-3 flex items-center space-x-2'>
                    <Tooltip title='Reload the website list' width="w-44"><ArrowPathIcon onClick={this.reloadList} className={"h-6 w-6 cursor-pointer " + (this.state.loadingReload ? "animate-spin": "")} aria-hidden="true" /></Tooltip>
                    <span><strong>{this.props.websites.length }</strong> <span>website{this.props.websites.length > 1 ? 's' : ''}</span> <Link to={"/website-add/"} className='inline-flex ml-2 px-4 py-1 bg-blue-400 rounded-lg shadow text-sm font-light text-neutral-100 transition text-center justify-center items-center hover:bg-blue-500 hover:text-white hover:shadow-md'><h3>Add New +</h3></Link></span>
                  </div>
                  {this.state.tags && this.state.tags.length
                  ? <div className='mb-2'>
                      {this.state.tags.map(t => <TagWithSelect key={t.title} title={t.title} color={t.color} onSelect={this.selectTag} />)}
                    </div> 
                  : null}
                  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
                    {this.props.websites.sort((a, b) => {
                      return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
                    }).filter(filter).map(i => {
                      return <Project key={i.id} website_id={i.id} token={this.props.token} setToken={this.props.setToken} addNotification={this.props.addNotification} updateTags={this.updateTags} {...i} />
                    })}

                    <Link to={"/website-add/"} className='flex w-full p-4 col-span-1 md:col-span-1 lg:col-span-1 bg-white rounded-lg shadow text-24-32 font-light text-gray-400 transition text-center justify-center items-center min-h-32 hover:text-gray-700 hover:shadow-lg'><h3>Add New +</h3></Link>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const HomeWithLocation = function(props) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  
  return (
    <Home query={query} {...props} />
  )
}

export default HomeWithLocation;
