async function apiPostRequest(token, url, body) {
  var options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };
  if (body) {
    options.body = JSON.stringify(body)
  }
  return fetch(url, options).then(data => data.json());
}

async function apiGetRequest(token, url) {
  var options = {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token
    }
  };
  return fetch(url, options).then(data => data.json());
}

export { apiGetRequest, apiPostRequest };