import React from 'react';
const PUBLIC_URL = process.env.PUBLIC_URL || 'http://localhost:4000';

if (typeof EventSource !== 'function') {
  // Mock for Test suites
  class EventSource {
    readyState;
    addEventListener(eventType, callbackFunc) {

    }
    close() {

    }
  }
  global.EventSource = EventSource;
}

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fade: false
    };
  }

  componentDidMount = () => {
    const delay = 5000;
    this.timeout_id = setTimeout(() => {
      this.fadeOut();
    }, delay);
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout_id);
  }

  fadeOut = () => {
    if (this.state.fade) {
      return;
    }
    const id = this.props.id;
    const removeNotification = this.props.removeNotification;

    this.setState({ fade: true }); 
    clearTimeout(this.timeout_id); 
    this.timeout_id = setTimeout(() => {
      removeNotification(id); 
    }, 300);
  }
  
  render() {
    const props = this.props;
    return (
      <div onClick={() => { this.fadeOut() }} className={'cursor-pointer text-white text-14-18 font-light p-4 rounded-sm shadow-md ' + (props.type === 'error' ? 'bg-red-700' : 'bg-emerald-700') + (this.state.fade ? ' transition duration-200 opacity-0' : '')} dangerouslySetInnerHTML={{__html: props.message}}></div>
    )  
  }
}

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  connectEventSource = () => {
    if (this.source?.readyState === 0 || this.source?.readyState === 1) {
      return;
    }

    if (!this.props.token) {
      return;
    }

    this.source = new EventSource(PUBLIC_URL + "/api/notifications/sse?bearer=" + this.props.token, {
      withCredentials: true
    });

    this.source.addEventListener('notification', (event) => {
      console.log(event);
      if (event?.type === 'notification') {
        try {
          let data = JSON.parse(event.data);
          console.log(data);
          this.props.addNotification(data.message, data.type, data.progress, data.track_id);
        } catch(e) {
          console.error(e);
        }
      }
    });

    this.source.addEventListener('message', (event) => {
      console.log(event);
      try {
        let data = JSON.parse(event.data);
        console.log(data);
        if (data?.nt === 'notification') {
          this.props.addNotification(data.message, data.type, data.progress, data.track_id);
        }
      } catch(e) {
        console.error(e);
      }
    });

    this.source.addEventListener('error', this.onError);
  }

  componentDidMount() {
    this.connectEventSource();
  }

  componentDidUpdate() {
    this.connectEventSource();
  }

  componentWillUnmount() {
    this.source?.close();
  }

  onError = (event) => {
    // console.error(event);
    setTimeout(() => {
      if (this.source?.readyState === 2) {
        setTimeout(() => {
          this.connectEventSource();
        }, 30000);
      }
    }, 100);
  }

  render() {
    const props = this.props;
    const { notifications, removeNotification } = props;
    // console.log(lastNotificationId, notifications);

    return (
      <div className='space-y-2 fixed top-2 w-full right-2 max-w-sm z-50'>
        {notifications.map(n => {
          return <Notification removeNotification={removeNotification} key={n.id} {...n} />
        })}
      </div>
    )
  }
}

export default Notifications;