import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={40}
      height={71}
      fill="none"
      viewBox="0 0 40 71"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.086 34.36L26 34.166l-1.364-3.062h-.007L19.872 20.48l-6.187 13.88c-.22.41-.493.766-.822 1.067l.001.006a3.363 3.363 0 01-1.644.817l.003.012c-.225.046-.455.07-.687.07h-.548l4.408 33.591c.219.63.63.63.876 0l4.38-10.731 3.805 10.704c.22.63.63.63.877 0l5.092-33.536-.192-.028c-.327 0-.65-.047-.958-.137a3.589 3.589 0 01-2.19-1.835z"
        fill="#F56F46"
        className={props.color || ''}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.05 1.588C-.143.93.24.465.897.465h5.448c.383 0 .74.329.849.657l3.997 15.742h.191L18.583.52c.137-.274.41-.52.794-.52h.849c.41 0 .684.219.794.52l7.2 16.344h.191l3.997-15.742c.082-.328.466-.657.85-.657h5.447c.657 0 1.013.466.849 1.123l-8.788 31.209c-.082.383-.466.657-.849.657h-.739a.908.908 0 01-.794-.52l-8.514-19h-.137l-8.46 19c-.136.274-.465.52-.793.52h-.74a.904.904 0 01-.848-.657L.049 1.587z"
        fill="#F56F46"
        className={props.color || ''}
      />
    </svg>
  );
}

export default Icon;