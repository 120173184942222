import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { 
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// import reportWebVitals from './reportWebVitals';
import useToken from '../useToken';
import Login from './Login';
import Home from '../routes/home';
import Website from '../routes/website';
import WebsiteAdd from '../routes/website_add';
import Notifications from './Notifications';
import Plugins from '../routes/plugins';
import Plugin from '../routes/plugin';
import Team from '../routes/team';
import Account from '../routes/account';
import Menu from './Menu';
import ForgotPassword from './ForgotPassword';
import NotFound from './NotFound';

const TrackProgress = {};
const notifications = [];

const Dashboard = function(props) {
  const [websites, setWebsites] = useState([]);
  const { token, setToken, unsetToken } = useToken();
  const [notificationId, setNotificationId] = useState(null);

  const updateTags = function(id, tags) {
    var i = websites.findIndex(w => w.id === id);
    if (i !== -1) {
      const clone = websites.slice(0);
      clone[i].tags = tags;
      setWebsites(clone);
    }
  }

  // we will use plain array (not state) to avoid racing conditions when pushing/removing from array
  const watchUpdateResult = function(track_id, on_finish) {
    TrackProgress[track_id] = on_finish;
  }

  let refreshNotificationsTo = null;
  const refreshNotifications = function() {
    clearTimeout(refreshNotificationsTo);
    refreshNotificationsTo = setTimeout(() => {
      setNotificationId(notifications[notifications.length-1]?.id + '|' + (new Date()).toISOString());
    }, 100);
  }

  const addNotification = function(message, type = 'success', progress = 0, track_id = '') {
    console.log('Adding notification', type, message);
    notifications.push({ id: uuidv4(), type, message });
    // every notification might have a track_id with a decimal progress from 0 to 1
    if (track_id && TrackProgress[track_id] && typeof TrackProgress[track_id] === 'function') {
      progress = parseFloat(progress) || 0;
      if (progress === 1) {
        TrackProgress[track_id](type);
        delete TrackProgress[track_id];
      }
    }
    refreshNotifications();
  }

  const removeNotification = function(id) {
    console.log('Removing notification', id);
    var index = notifications.findIndex(item => item.id === id);
    if (index === -1) {
      return;
    }
    notifications.splice(index, 1);
    refreshNotifications();
  }

  if (!token) {
    return <div id="maintenance-dashboard" className="overflow-hidden pb-3">
      <BrowserRouter>
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword addNotification={addNotification} />} />
          <Route path="/*" element={<Login setToken={setToken} addNotification={addNotification} />} />
        </Routes>
        <Notifications token={token} lastNotificationId={notificationId} notifications={notifications.slice()} addNotification={addNotification} removeNotification={removeNotification} />
      </BrowserRouter>
    </div>;
  }

  const menu = <Menu unsetToken={unsetToken} token={token} key="menu" />;

  return (
    <div id="maintenance-dashboard" className="overflow-hidden pb-3">
      <Notifications token={token} lastNotificationId={notificationId} notifications={notifications.slice()} addNotification={addNotification} removeNotification={removeNotification} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home menu={menu} token={token} setToken={setToken} unsetToken={unsetToken} websites={websites} setWebsites={setWebsites} updateTags={updateTags} addNotification={addNotification} />} />
          <Route path="/plugins" element={<Plugins menu={menu} token={token} setToken={setToken} unsetToken={unsetToken} addNotification={addNotification} websites={websites} setWebsites={setWebsites} watchUpdateResult={watchUpdateResult} />} />
          <Route path="/team" element={<Team menu={menu} token={token} setToken={setToken} unsetToken={unsetToken} addNotification={addNotification} />} />
          <Route path="/account" element={<Account menu={menu} token={token} setToken={setToken} unsetToken={unsetToken} addNotification={addNotification} />} />
          <Route path="/website/:id" element={<Website menu={menu} token={token} setToken={setToken} unsetToken={unsetToken} websites={websites} setWebsites={setWebsites} addNotification={addNotification} watchUpdateResult={watchUpdateResult} />} />
          <Route path="/plugin/:id" element={<Plugin menu={menu} token={token} setToken={setToken} unsetToken={unsetToken} addNotification={addNotification} watchUpdateResult={watchUpdateResult} />} />
          <Route path="/website-add" element={<WebsiteAdd menu={menu} token={token} setToken={setToken} unsetToken={unsetToken} addNotification={addNotification} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default Dashboard;