import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";

const WebsiteAdd = function(props) { 
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState();
  const [url, setUrl] = useState();
  const [protocol, setProtocol] = useState('https://');
  const [connection_string, setConnectionString] = useState();
  const addNotification = props.addNotification;
  const navigate = useNavigate();

  async function submitWebsite (body) {
    return fetch('/api/website-add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + props.token
      },
      body: JSON.stringify(body)
    }).then(data => data.json());
  }

  const submit = async (event) => {
    event.preventDefault();
    if (loading) {
      return false;
    }
    setLoading(true);
    try {
      const response = await submitWebsite({
        'title': title,
        'url': protocol + url,
        'connection_string': connection_string
      });
      if (response && response.success) {
        // some kind of notification that it was fine, and redirect to Home
        addNotification('Connection successful! Reloading your list of websites..', 'success');
        navigate("/?reload=1", { replace: true });
        return false;
      } else {
        addNotification(response?.message, 'error');
        // display error
      }  
    } catch(error) {
      console.error(error);
    }
    setLoading(false);
  }

  return (
    <div className="container">
      <div className="grid grid-cols-1 md:grid-cols-6 md:gap-4">
        <div className="col-span-1 md:col-span-2 xl:col-span-1 mt-2">
          {props.menu}
        </div>
        <div className="col-span-4 xl:col-span-5">
          <div className="mt-2 mb-4">
            <h2 className="text-32-40 font-semibold mb-3">Connect New Website</h2>
            <div className="bg-white rounded-lg shadow overflow-hidden p-6">
              <form action="#" onSubmit={submit}>
                <div className="space-y-6">
                  
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                        Title
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input onChange={e => setTitle(e.target.value)} required type="text" name="title" id="title" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="My Shiny Website" />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-3">
                      <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                        Website URL
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span onClick={() => { protocol === 'http://' ? setProtocol('https://') : setProtocol('http://') }} className="cursor-pointer inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          {protocol}
                        </span>
                        <input onChange={e => setUrl(e.target.value)} required type="text" name="url" id="url" className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md border-gray-300" placeholder="www.example.com" />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="connection_key" className="block text-sm font-medium text-gray-700">
                      Connection Key
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input onChange={e => setConnectionString(e.target.value)} required type="text" name="connection_key" id="connection_key" className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md border-gray-300" placeholder="a-long-combination-of-characters-you-will-find-in-plugin-settings" />
                    </div>
                  </div>

                  <div className='text-right'>
                    <button type="submit" className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
                      <svg className={(!loading ? "hidden" : "") + " motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Submit
                    </button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebsiteAdd;