import React from 'react';
import { apiPostRequest } from '../apiRequest';
import { ArrowTopRightOnSquareIcon, ArrowPathIcon } from '@heroicons/react/20/solid';

class OneClickLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  login = async () => {
    if (this.state.loading) {
      return false;
    }
    this.setState({
      loading: true
    });
    const token = this.props.token;
    const addNotification = this.props.addNotification;
    const website_id = this.props.website_id;
    const url = this.props.url;
    try {
      const response = await apiPostRequest(token, `/api/website/${website_id}/login`);
      if (response && response.success) {
        // construct a POST form
        const form = document.createElement('form');
        form.action = url;
        form.target = '_blank';
        form.method = 'post';
        form.innerHTML = response.form;
        document.body.appendChild(form);
        form.submit();
        form.remove();
      } else {
        // display error
        addNotification(response?.message || 'An unexpected error occured. Please, try again later or contact the administrator.', 'error');
      }  
    } catch(error) {
      // display error
      console.error(error);
      addNotification(error?.message || 'An unexpected error occured. Please, try again later or contact the administrator.', 'error');
    }

    this.setState({
      loading: false
    });
  }

  render() {
    return <span className="font-normal text-base cursor-pointer inline-flex items-center text-blue-400 hover:text-blue-600" onClick={this.login}>
        <span className='sr-only'>Login</span>
        <span className='space-x-1 inline-flex items-center'>
          {this.props.text ? <span>{this.props.text}</span> : null}
          {this.state.loading ?
            <span className="animate-spin">
              <ArrowPathIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          : <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true" />}
        </span>
    </span>
  }
}

export default OneClickLogin;