import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Login(props) {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const addNotification = props.addNotification;

  async function loginUser (credentials) {
    return fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json());
  }

  const submit = async (event) => {
    event.preventDefault();
    if (loading) {
      return false;
    }
    setLoading(true);
    try {
      const response = await loginUser({
        'email': username,
        'password': password
      });
      if (response && response.token) {
        props.setToken(response);
      } else {
        console.log(response);
        const message = response?.message || 'Unexpected error occured. Please, try again later.';
        addNotification(message, 'error');
        setLoading(false);
        // display error
      }  
    } catch(error) {
      console.error(error);
      setLoading(false);
    }
  }

  return(
    <div className="m-8 max-w-lg mx-auto">
      <h1 className='text-center text-gray-900 text-3xl font-extrabold tracking-tight mt-6 mb-8'>Login to Maintenance</h1>
      <div className='p-6 bg-white rounded shadow transition hover:shadow-lg'>
        <form className='space-y-6' onSubmit={submit}>
          <label className='block'>
            <p className='block text-sm font-medium text-gray-700'>E-mail</p>
            <input onChange={e => setUsername(e.target.value)} type="email" name="email" className='appearance-none mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md px-3 py-2' />
          </label>
          <label className='block'>
            <p className='block text-sm font-medium text-gray-700'>Password</p>
            <input onChange={e => setPassword(e.target.value)} type="password" name="password" className='appearance-none mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md px-3 py-2' />
          </label>
          <div className="flex justify-between items-center">
            <div>
              <Link to="/forgot-password" className="text-sm text-sky-600 hover:text-sky-800 hover:underline">Forgot password?</Link>
            </div>
            <div className='text-right'>
              <button type="submit" className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'>
                <svg className={(!loading ? "hidden" : "") + " motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}