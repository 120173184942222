import React from 'react';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import { apiGetRequest, apiPostRequest } from '../apiRequest';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'loading': true,
      'submitting': false,
      'name': '',
      'email': '',
      'password': '',
      'password_confirm': ''
    };
  }

  componentDidMount() {
    if (this.state.loading || this.state.reload) {
      apiGetRequest(this.props.token, '/api/account').then((response) => {
        this.setState({
          loading: false,
          email: response?.account?.email,
          name: response?.account?.name
        });
      }).catch((err) => {
        this.props.addNotification(err?.message, 'error');
      })
    }
  }

  setName = (name) => {
    this.setState({
      name: name
    });
  }

  setEmail = (email) => {
    this.setState({
      email: email
    });
  }

  setPassword = (password) => {
    this.setState({
      password: password
    });
  }

  setPasswordConfirm = (password) => {
    this.setState({
      password_confirm: password
    });
  }

  submit = async (event) => {
    event.preventDefault();
    if (this.state.submitting) {
      return false;
    }
    this.setState({
      submitting: true
    });
    const addNotification = this.props.addNotification;
    try {
      const response = await apiPostRequest(this.props.token, '/api/account', {
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
        password_confirm: this.state.password_confirm
      });
      this.setState({
        submitting: false
      });
      if (response && response.success) {
        // some kind of notification that it was fine, and redirect to Home
        addNotification('Profile saved successfully!', 'success');
        return false;
      } else {
        addNotification(response?.message, 'error');
        // display error
      }
    } catch(e) {
      this.setState({
        submitting: false
      });
      addNotification(e?.message, 'error');
    }
  }

  render() {
    return (
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-6 md:gap-4">
          <div className="col-span-1 md:col-span-2 xl:col-span-1 mt-2">
            {this.props.menu}
          </div>
          <div className="col-span-4 xl:col-span-5">
            <div className="mt-2 mb-4">
              {this.state.loading 
                ? <div>Loading...</div> 
                : <div>
                    <div className='align-middle inline-block min-w-full'>
                      <h2 className="text-32-40 font-semibold mb-3">My Profile</h2>
                      <div className="bg-white rounded-lg shadow overflow-hidden p-6">
                        <form action="#" onSubmit={this.submit}>
                          <div className="space-y-6">
                            
                            <div className="grid grid-cols-4 gap-6">
                              <div className="col-span-4 sm:col-span-2">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                  Name
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <input value={this.state.name} onChange={e => this.setName(e.target.value)} required type="text" name="name" id="name" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Your name" />
                                </div>

                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mt-6">
                                  Email
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <input value={this.state.email} onChange={e => this.setEmail(e.target.value)} required type="text" name="email" id="email" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" placeholder="Your email can't be empty" />
                                </div>
                              </div>
                              <div className="col-span-4 sm:col-span-2">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                  Password
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <input value={this.state.password} onChange={e => this.setPassword(e.target.value)} type="password" name="password" id="password" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" />
                                </div>

                                <label htmlFor="password_confirm" className="block text-sm font-medium text-gray-700 mt-6">
                                  Password Confirm
                                </label>
                                <div className="mt-1 flex rounded-md shadow-sm">
                                  <input value={this.state.password_confirm} onChange={e => this.setPasswordConfirm(e.target.value)} type="password" name="password_confirm" id="password_confirm" className="flex-1 block w-full rounded-md sm:text-sm border-gray-300" />
                                </div>
                              </div>
                            </div>

                            <div className='text-right'>
                              <button type="submit" className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
                                <svg className={(!this.state.submitting ? "hidden" : "") + " motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Submit
                              </button>
                            </div>

                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const AccountWithLocation = function(props) {
  const location = useLocation();
  const query = queryString.parse(location.search);
  
  return (
    <Account query={query} {...props} />
  )
}

export default AccountWithLocation;
