import PluginUpdateButton from "./PluginUpdateButton";
import Tooltip from "./Tooltip";
import { ShieldExclamationIcon } from '@heroicons/react/20/solid';
import { Link } from "react-router-dom";

function Plugins(props) {
  return (
    <div className="border-t border-gray-200 mt-2">
      <dl>
        <div className="hidden md:grid bg-gray-50 py-5 sm:grid-cols-4 px-6 sm:gap-4">
          <dt className="text-sm font-medium text-gray-700 sm:col-span-2">
            Plugin
          </dt>
          <dd className="mt-1 text-sm font-medium text-gray-700 sm:mt-0 sm:col-span-1">
            Version
          </dd>
          <dd className="mt-1 text-sm font-medium text-gray-700 sm:mt-0 sm:col-span-1">
            Available Update
          </dd>
        </div>

        {props.website?.plugins?.sort((a, b) => {
          return (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1);
        }).sort((a, b) => {
          if (a.available_version && !b.available_version) {
            return -1;
          }
          if (!a.available_version && b.available_version) {
            return 1;
          }
          return 0;
        }).map((p, i) => {
          return <div key={p.plugin_id} className={(i % 2 === 0 ? "bg-white" : "bg-gray-50") + " px-6 py-5 sm:grid sm:grid-cols-4 sm:gap-4"}>
            <dt className="text-sm sm:col-span-2">
              <div className={p.is_active ? 'font-medium text-gray-900' : 'font-regular text-gray-500'}>
                <Link to={"/plugin/" + p.plugin_id}>{p.title}</Link>
                {!p.is_active ? <div><em className="text-gray-300">Deactivated</em></div> : null}
              </div>
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
              {p.version}
              {p.has_vulns 
              ? <div className='mr-1 inline-block text-14-16'><Tooltip width="w-36" title="Vulnerable Release">
                <ShieldExclamationIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
              </Tooltip></div>
              : null}
            </dd>
            <dd className="mt-1 text-sm font-semibold text-gray-900 sm:mt-0 sm:col-span-1">
              {p.available_version}
              {p.available_version
              ? <PluginUpdateButton available_version={p.available_version} update_package={p.update_package} website_id={props.website.id} plugin_id={p.plugin_id} addNotification={props.addNotification} token={props.token} watchUpdateResult={props.watchUpdateResult} /> 
              : null}
            </dd>
          </div>
        })}
      </dl>
    </div>
  )
}

export default Plugins;