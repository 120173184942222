import React, { createRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import Tooltip from '../components/Tooltip';
import PlatformUpdateButton from "../components/PlatformUpdateButton";
import { ShieldExclamationIcon } from '@heroicons/react/24/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import OneClickLogin from "../components/OneClickLogin";
import { apiPostRequest } from "../apiRequest";
import Plugins from "../components/Plugins";

function Summary(props) {
  const website = props.website;
  const summary = props.website?.summary;
  if (!summary) {
    return null;
  }
  
  const has_vulns = (props.website?.summary?.vulnerabilities?.length) || false;

  return (
    <div className="border-t border-gray-200 mt-2">
      <dl>
        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-900">
            Last Sync
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {website?.last_sync ? moment(website.last_sync).format('MMMM Do, h:mm:ss A') + ' EST' : 'Never'}
          </dd>
        </div>
        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-900">
            Search engines visibility
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {(summary?.blog_public === '1') ? <span className="text-green-600 font-semibold">Visible</span> : <span className="text-red-600 font-semibold">Not visible</span>}
          </dd>
        </div>
        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-900">
            Platform
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 capitalize">
            {website?.platform} 
              <span className="ml-1">
                {has_vulns 
                  ? <div className='mr-1 inline-block text-14-16'><Tooltip width="w-36" title="Vulnerable Release">
                    <ShieldExclamationIcon className="h-4 w-4 text-red-500" aria-hidden="true" />
                  </Tooltip></div>
                  : null}
                    
                {summary?.version_update_available 
                  ? <span>
                      <Tooltip width='w-48' className={'inline-block px-0.5 rounded-sm text-white ' + (has_vulns ? 'bg-red-500' : 'bg-yellow-500')} title={`Update to ${summary.version_update_available} is available`}>
                        {summary?.version}
                      </Tooltip>
                      <span className='relative -top-1'>
                        <PlatformUpdateButton website_id={props.website.id} addNotification={props.addNotification} token={props.token} watchUpdateResult={props.watchUpdateResult} />
                      </span>
                    </span> 
                  : <Tooltip className='inline-block bg-green-500 px-0.5 rounded-sm text-white' title="Up to date">{summary?.version}</Tooltip>
                }
              </span>
          </dd>
        </div>
        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-900">
            PHP Version:
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {summary?.php_version}
          </dd>
        </div>
        <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-900">
            Home Path:
          </dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {summary?.home_path}
          </dd>
        </div>
        {website?.ithemes_last_scan ? 
          <div className="odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">
              Last IThemes Security scan
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {website?.ithemes_last_scan?.value?.code === 'clean'
                ? 'Clean'
                : website?.ithemes_last_scan?.value?.code === 'vulnerable-software'
                ? <div>
                    <div><span className="inline-flex items-center">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
                      <span className="text-red-500">Vulnerable Release</span>
                    </span></div>
                    <div>
                      {website?.ithemes_last_scan?.value?.data?.results?.entries?.vulnerabilities.map((elem, i) => {
                        if (elem.type === 'plugin') {
                          return <div key={i}>
                            {elem.software?.label}
                            <span className="inline-flex gap-1 divide-x divide-gray-300">
                              {elem.software?.latest_version ? <span className="pl-1">&lt;= {elem.software?.latest_version}</span> : elem.issues?.map?.((issue => <span className="pl-1" key={issue.id}>
                                {issue.affected_in}
                              </span>))}
                            </span>
                          </div>
                        } else {
                          return <div key={i}>
                            {elem.software?.label} {elem.software?.latest_version}
                          </div>
                        }
                      })}
                    </div>
                  </div>
                : website?.ithemes_last_scan?.value?.code}
            </dd>
          </div>
        : null}
      </dl>
    </div>
  )
}

function Themes(props) {
  return (
    <div className="border-t border-gray-200 mt-2">
      <dl className="bg-gray-50 px-6 py-5 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">

        {props.website?.themes?.sort((a, b) => {
          return (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1);
        }).sort((a, b) => {
          if (a.is_active && !b.is_active) {
            return -1;
          }
          if (!a.is_active && b.is_active) {
            return 1;
          }
          return 0;
        }).map((p, i) => {
          return <div key={p.id} className="sm:col-span-1">
            <dt className="text-sm">
              <div className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div className="aspect-4/3 relative">
                  <a href={p.screenshot} rel="noreferrer" target="_blank" className="absolute top-0 bottom-0 w-full overflow-hidden block p-2 border-2 border-gray-100 bg-white transition hover:border-gray-200"><img alt='' src={p.screenshot} className="w-full h-full object-cover" /></a>
                  {p.has_vulns 
                  ? <div className='mr-1 inline-block text-14-16'><Tooltip width="w-36" title="Vulnerable Release">
                    <ShieldExclamationIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                  </Tooltip></div>
                  : null}
                </div>
              </div>
              <div className={p.is_active ? 'font-medium text-gray-900 mt-1' : 'font-regular text-gray-500 mt-1'}>
                {p.title}
                {!p.is_active ? <div><em className="text-gray-300">Inactive</em></div> : null}
                <div className="text-gray-900">{p.version}</div>
              </div>
            </dt>
          </div>
        })}
      </dl>
    </div>
  )
}

class Website extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      websites: props.websites,
      website_title: '',
      website: {}
    };
    if (props.websites && props.websites.length) {
      var w = props.websites.filter(w => w.id === props.params.id);
      if (w && w.length) {
        // this.state.website = w[0];
        this.setState({
          website: w[0],
          website_title: w[0].title || ''
        });
        console.log(w[0]);
      }
    }

    this.websiteTitleRef = createRef();
  }

  changeWebsiteTitle = () => {
    if (this.websiteTitleRef?.current) {
      const new_title = this.websiteTitleRef.current.innerText;
      this.setState({
        website_title: new_title
      });
      apiPostRequest(this.props.token, `/api/website/${this.props.params.id}`, {
        title: new_title
      }).then(response => {
        
      }).catch(err => {
        this.addNotification(err?.message, 'error');
      })
    }
  }

  deleteWebsite = () => {
    Swal.fire({
      title: 'Are you sure you want to delete ' + this.state.website?.title + '?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        title: 'text-lg font-light'
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`/api/website/${this.props.params.id}/delete`, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + this.props.token
          }
        }).then(response => {
          return response.json();
        }).then(data => {
          if (data && data.success) {
            this.props.navigate("/?reload=1", { replace: true });
          } else if ((!data || !data.success) && this.props.unsetToken) {
            this.props.unsetToken();
          }
        });
      } else {
        
      }
    });
  }

  componentDidMount() {
    // additional details
    fetch(`/api/website/${this.props.params.id}`, {
      headers: {
        'Authorization': 'Bearer ' + this.props.token
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data && data.website) {
        this.setState({ 
          website: data.website, 
          website_title: data.website?.title,
          loading: false 
        });
      } else if (!data || !data.success) {
        this.props.unsetToken();
      } else {
        this.setState({ loading: false });  
      }
    });
  }
  
  render() {
    var url = '';
    if (this.state.website && this.state.website.url) {
      url = this.state.website.url.replace(/\/$/, '');
    }
    const id = this.props.params.id;
    return (
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-6 md:gap-4">
          <div className="col-span-1 md:col-span-2 xl:col-span-1 mt-2">
            {this.props.menu}
          </div>
          <div className="col-span-4 xl:col-span-5">
            <div className="mt-2 mb-4">
              {this.state.loading && !this.state.website.url
              ? <div>
                  <h2 className="text-40-56 font-bold">Loading...</h2>
                </div>
              : <div>
                  <h2 className="text-40-56 font-bold" ref={this.websiteTitleRef} contentEditable="true" onBlur={this.changeWebsiteTitle} dangerouslySetInnerHTML={{ __html: this.state.website_title }}></h2>
                  <div className="bg-white rounded-lg shadow overflow-hidden">
                    <div className="md:grid md:grid-cols-3">
                      <div className="col-span-2 pt-4 md:border-r">
                        <div className="px-6 mb-3">
                          <div className=""><a href={url + '/'} className="text-blue-600 hover:text-blue-800 hover:underline max-w-full" rel="noreferrer" target="_blank">{url}</a></div>
                          <div className=" text-sm text-gray-400">Connection key: <code className="text-gray-700">{this.state.website.connection_string}</code></div>
                        </div>

                        <div className="px-6">
                          <h3 className="mt-4 leading-6 text-lg font-medium">Summary</h3>
                          <p className="mt-0 text-sm text-gray-400">
                            Quick summary and main settings.
                          </p>
                        </div>
                        <Summary website={this.state.website} addNotification={this.props.addNotification} token={this.props.token} watchUpdateResult={this.props.watchUpdateResult} />

                        <hr />

                        <div className="px-6">
                          <h3 className="mt-8 leading-6 text-lg font-medium">Themes</h3>
                          <p className="mt-0 text-sm text-gray-400">
                            Available themes
                          </p>
                        </div>
                        <Themes website={this.state.website} addNotification={this.props.addNotification} token={this.props.token} watchUpdateResult={this.props.watchUpdateResult} />

                        <hr />

                        <div className="px-6">
                          <h3 className="mt-8 leading-6 text-lg font-medium">Plugins</h3>
                          <p className="mt-0 text-sm text-gray-400">
                            List of all the plugins and available updates.
                          </p>
                        </div>
                        <Plugins website={this.state.website} addNotification={this.props.addNotification} token={this.props.token} watchUpdateResult={this.props.watchUpdateResult} />

                      </div>
                      <div className="col-span-1 pl-6 py-4 space-y-4 pr-4">
                        <h3 className="text-xl font-light mb-4">Tools &amp; Benchmarks</h3>
                        <div className="space-y-1">
                          <p><OneClickLogin text="Login as administrator" website_id={id} url={url + '/'} token={this.props.token} addNotification={this.props.addNotification} /></p>
                          <p><a className="text-blue-400 hover:text-blue-600" href={url + '/'} target="_blank" rel="noreferrer">View Homepage</a></p>
                        </div>
                        <div className="space-y-1">
                          <p><a className="font-regular cursor-pointer text-blue-400 hover:text-blue-600" href={"https://www.webpagetest.org/?url=" + encodeURIComponent(url + '/')} rel="noreferrer" target="_blank">WebPageTest</a></p>
                        </div>
                        <div className="bg-sky-50 h-1"></div>
                        <div>
                          <h3 className="text-xl font-light mb-4">Danger Zone</h3>
                          <p><span className="inline-block bg-red-500 px-5 py-3 text-white font-light text-16-20 rounded-md cursor-pointer shadow-md transition hover:bg-red-600 hover:shadow-none" onClick={this.deleteWebsite}>Delete Website</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WebsiteWithRouter = (props) => {
  const navigate = useNavigate();

  return <Website 
    {...props}
    navigate={navigate}
    params={useParams()}
  />
};

export default WebsiteWithRouter;