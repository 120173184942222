import { useState } from "react";
import { ChevronDoubleUpIcon, ArrowPathIcon, CheckIcon } from '@heroicons/react/20/solid'
import { apiPostRequest } from "../apiRequest";

function PlatformUpdateButton(props) {
  const [updating, setUpdating] = useState(0);

  const addNotification = props.addNotification;
  const watchUpdateResult = props.watchUpdateResult;
  const website_id = props.website_id;
  const token = props.token;

  const update = async (event) => {
    event.preventDefault();
    setUpdating(1);

    try {
      const response = await apiPostRequest(token, '/api/platform-update', {
        'website_id': website_id
      });
      if (response && response.success) {
        addNotification('Website update has been scheduled successfully.', 'success');
        watchUpdateResult(response.track_id, (type) => {
          setUpdating(type === 'success' ? 2 : 0);
        });
      } else {
        // display error
        addNotification(response?.message || 'Something bad happened. Please try again, or contact the administrator', 'error');
        setUpdating(0);
      }  
    } catch(error) {
      // display error
      console.error(error);
      addNotification('Update failed: ' + error.message, 'error');
      setUpdating(0);
    }
  }

  return (
    updating === 2
    ? <span
        className="ml-2 w-7 h-7 relative align-top inline-flex justify-center items-center px-1 py-1 rounded-full bg-gray-800 text-sm font-medium text-white transition group"
      >
        <span className="sr-only">Updated</span>
        <CheckIcon className="h-4 w-4" aria-hidden="true" />
      </span>
    : (
    updating === 1 ? 
    <span
      className="ml-2 w-7 h-7 relative align-top inline-flex justify-center items-center px-1 py-1 rounded-full bg-yellow-200 text-sm font-medium text-gray-700 transition group"
    >
      <span className="sr-only">Updating the website..</span>
      <ArrowPathIcon className="h-4 w-4 animate-spin" aria-hidden="true" />
    </span>
    : 
    <button 
      type="button"
      onClick={update}
      className="ml-2 w-7 h-7 relative align-top inline-flex justify-center items-center px-1 py-1 rounded-full bg-green-200 text-sm font-medium text-gray-700 hover:bg-green-300 transition group"
    >
      <span className="sr-only">Update Website</span>
      <ChevronDoubleUpIcon className="h-4 w-4 transition group-hover:-translate-y-0.5" aria-hidden="true" />
    </button>
    )
  )
}

export default PlatformUpdateButton;