function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    } else if (navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text).then(function() {
            
        }, function(err) {
            prompt('Copy to clipboard', text);
            console.error('Async: Could not copy text: ', err);
        });
    }
}

export default copyToClipboard;